import React, { useContext, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Form, Button, List, Input, Avatar, AutoComplete } from 'antd';

import { GlobalContext } from '../context/GlobalContextProvider';
import { usePaper } from '../hooks';
import { apiRequester, handleError } from '../utility';

export const SharePaperModal = () => {
    const context = useContext(GlobalContext);
    const { sharePaperWithEmail, removePaperSharedWithEmail, paperLoading } = usePaper();
    const [shareForm] = Form.useForm();
    const [options, setOptions] = useState<{ value: string }[]>([]);

    const searchSuggestions = async (text: string) => {
        try {
            const emails = await apiRequester.getEmailSuggestions({ text });
            const values = emails.map(email => ({ value: email }));
            setOptions(values);
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <Modal
            visible={!!context.sharePaper}
            title="Share Paper"
            closable={true}
            footer={null}
            onCancel={() => context.setSharePaper(undefined)}
        >
            <Form
                layout="vertical"
                onFinish={values => sharePaperWithEmail({ paperId: context.sharePaper?._id!, email: values.email })}
                form={shareForm}
            >
                <Form.Item label="Email to share with" name="email" style={{ width: '100%' }}>
                    <AutoComplete options={options} onSearch={searchSuggestions} style={{ width: '100%' }} />
                </Form.Item>
                <Button type="primary" onClick={() => shareForm.submit()} loading={paperLoading} block>
                    Share
                </Button>
                <Form.Item></Form.Item>
            </Form>
            <List
                loading={paperLoading}
                header="Shared With"
                dataSource={context.sharePaper?.sharedWith}
                renderItem={user => (
                    <List.Item
                        extra={
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                type="link"
                                onClick={() =>
                                    removePaperSharedWithEmail({
                                        email: user.emailId!,
                                        paperId: context.sharePaper?._id!,
                                    })
                                }
                            />
                        }
                    >
                        <List.Item.Meta
                            avatar={<Avatar>{user.firstName ? user.firstName[0] : ''}</Avatar>}
                            description={user.emailId}
                            title={`${user.firstName || ''} ${user.lastName || ''}`}
                        />
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default SharePaperModal;
